<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <van-tabs
        v-model="active"
        :animated="true"
        :class="appStatus == 1 ? 'disappear' : ''"
      >
        <van-tab title="详情页" name="a">
          <BasicTop :basicTop="basicTop" numberrows="2" />
          <Steps
            :levels="levels"
            v-if="levels && appStatus == 1"
            :hierarchy="hierarchy"
          />
          <div class="content-show">
            <van-tabs :animated="true" class="disappear">
              <van-tab>
                <van-list :finished="true" finished-text="没有更多了">
                  <van-collapse v-model="activeNames">
                    <van-collapse-item title="基本信息" name="1" icon="label">
                      <van-cell-group class="group">
                        <van-cell
                          title="制造计划名称"
                          :value="details.planTopic"
                        />
                        <van-cell
                          title="合同编号"
                          :value="details.contractno"
                        />
                        <van-cell
                          title="项目单位"
                          :value="details.buildcompName"
                        />
                        <van-cell title="项目名称" :value="details.projname" />
                        <van-cell title="物资编码" :value="details.prodcode" />
                        <van-cell title="物资名称" :value="details.prodName" />
                        <van-cell
                          title="装置名称"
                          :value="details.deviceName"
                        />
                        <van-cell title="装置位号" :value="details.itemno" />
                        <van-cell title="数量"
                          ><span>{{ details.qty }}{{ details.measuint }} </span>
                        </van-cell>
                        <van-cell
                          title="交货期"
                          :value="details.diliverydate"
                        />
                        <van-cell title="生产计划编号" :value="details.jobno" />
                        <van-cell
                          title="编制日期"
                          :value="details.createtime"
                        />
                      </van-cell-group>
                    </van-collapse-item>
                    <van-collapse-item
                      title="制造计划详情"
                      name="2"
                      icon="label"
                    >
                      <van-cell-group class="group groupcolor">
                        <van-cell
                          title="【制造计划详情】"
                          is-link
                          @click="onDetail"
                        />
                      </van-cell-group>
                    </van-collapse-item>
                    <van-collapse-item
                      title="附件"
                      name="3"
                      icon="label"
                      class="groupbox"
                    >
                      <van-cell-group
                        class="group"
                        :class="[index == 0 ? '' : 'only']"
                        v-for="(item, index) in files"
                        :key="index"
                      >
                        <van-cell title="附件类型" :value="item.name" />
                        <van-cell title="文件名">
                          <div
                            v-for="(item, index) in item.data"
                            :key="index"
                            class="cell"
                          >
                            <a
                              v-if="
                                item.suffix != 'rar' && item.suffix != 'zip'
                              "
                              :href="
                                '/api-ec/file/FileuploadAction/' +
                                  item.id +
                                  '-' +
                                  item.title
                              "
                              >{{ item.title }}</a
                            >
                            <span v-else @click="onCompression">{{
                              item.title
                            }}</span>
                          </div>
                        </van-cell>
                      </van-cell-group>
                    </van-collapse-item>
                  </van-collapse>
                </van-list>
              </van-tab>
            </van-tabs>
          </div>
        </van-tab>
        <van-tab title="审批记录" name="c" v-if="appStatus != 1">
          <div
            class="record"
            v-for="(item, index) in details.wfapproveinfos"
            :key="index"
          >
            <van-cell-group>
              <van-cell title="审批层级" :value="item.node"></van-cell>
              <van-cell title="审批节点" :value="item.nodename"></van-cell>
              <van-cell title="审批人" :value="item.userName"></van-cell>
              <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
              <van-cell title="审批结论" :value="item.resultType"></van-cell>
              <van-cell
                title="审批意见"
                :value="item.result"
                v-if="item.resultType == '不同意'"
              ></van-cell>
            </van-cell-group>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      files: [],
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      levels: null,
      hierarchy: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "manufactureName",
            label: "制造商",
          },
          {
            prop: "erpcontractno",
            label: "采购合同号",
          },
          {
            prop: "diliverydate",
            label: "交货日期",
          },
          {
            prop: "qty",
            label: "采购数量",
            unit: "1",
          },
        ],
      },
    };
  },
  created() {
    this.getStockaffirmDetail();
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async getStockaffirmDetail() {
      console.log();
      const params = {
        id: this.id,
        type: "procManuPlanAudit",
        changeid: this.$route.query.changeId ? this.$route.query.changeId : 0,
      };
      let data = await this.$api.ec.procmanuplan(params);
      if (data) {
        this.loadingfalg = false;
      }
      this.details = data;
      data.unit = data.measuint;
      this.basicTop.data = data;
      this.title = data.planTopic;
      // this.levels = Number(data.levels)
      if (this.details.files) {
        this.details.files.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
        this.files = this.sortArr(this.details.files, "fileCodename");
      }

      console.log(this.files);
      data.wfnodeVOs.map((item, index) => {
        if (item.isNodeaction == 1) {
          this.levels = index + 1;
          return;
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
    },
    sortArr(arr, str) {
      var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;

      // 按照特定的参数将数组排序将具有相同值得排在一起
      arr = arr.sort(function(a, b) {
        var s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });

      if (arr.length) {
        _tmp = arr[0][str];
      }
      // console.log( arr );
      // 将相同类别的对象添加到统一个数组
      for (var i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      // 将最后的内容推出新数组
      _arr.push(_t); //获取到所有整合数据
      console.log(_arr); //可以得到图三
      // 终极整合
      var integration = [];
      _arr.forEach((item) => {
        var eee = { name: "", data: [] };
        item.forEach((self) => {
          if (eee.name != self.fileCodename) {
            eee.name = self.fileCodename;
            eee.data.push(self);
          } else {
            eee.data.push(self);
          }
        });
        integration.push(eee);
      });
      console.log(integration); //图二
      return integration;
    },

    onDetail() {
      let query = {
        id: this.details.id,
        type: "procmanuplan",
      };
      this.$router.push({ name: "detail", query });
    },
    ondown(item) {
      let query = {
        id: item.id,
      };
      this.$router.push({ name: "preview", query });
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          id: this.id,
          audit: 0,
          leadexam: message,
          changeid: 0,
        };
      } else {
        params = {
          id: this.id,
          audit: 1,
          leadexam: "同意",
          changeid: 0,
        };
      }
      let data = await this.$api.ec.procmanuplanApproval(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecManufacturConfirmapproval",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    onClickLeft() {
      if (this.showage) {
        // this.$router.go(-1);
        this.$router.push({ name: "ecManufacturConfirmapproval" });
      } else {
        this.showage = true;
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onLoad() {},
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
/deep/.groupcolor {
  .van-cell {
    .van-cell__title {
      color: #2d8aff;
    }
    .van-icon {
      color: #2d8aff;
    }
  }
}
/deep/.groupbox {
  .van-collapse-item__wrapper {
    .van-collapse-item__content {
      background-color: transparent !important;
    }
  }
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
.only {
  margin-top: 10px;
}
</style>
